import React, { useState } from 'react'
import myImage from './image.png';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Import Bootstrap JS
function Header() {
	const [menuOpen, setMenuOpen] = useState(false);

	const onToggleMenu = () => {
		setMenuOpen(true);
	};
	const onCloseMenu = () => {
		setMenuOpen(false); // Close the menu
	};

	return (
		<div>

			<div className='bg-red-700 text-center p-2 md:text-[15px] text-[10px] text-white' style={{ color: 'white' }}>Closed: December 24th (Christmas Eve), December 25th (Christmas Day), and December 30th, 31st, and January 1st (Vacation).</div>
			<div class="bg-green-800 ">
				<div class="container px-2">

					<div class="flex items-center justify-between pt-4 pb-2">

						<div class="hidden sm:flex sm:items-center">
							<p class="text-white text-sm font-semibold hover:text-purple-600 mr-4">Claysburg, PA <br /> <a href="tel:+18142395865" className='text-white'> <i class="bi bi-telephone"> (814) 239-5865</i> </a></p>
						</div>

						<div className=" d-flex justify-content-center " >
							<a href="/" class="d-block" style={{ height: '35%', width: '70%' }} ><img src={myImage} alt="Los Angeles" /> </a>

						</div>



						<div class="hidden sm:flex sm:items-center">
							<div class="text-white text-sm font-semibold border px-4 py-2 rounded-lg hover:text-purple-600 hover:border-purple-600"><a href='/products' className='text-white'> View Product </a></div>
						</div>


					</div>
					<div class="flex items-center lg:order-2 pb-2 px-3">
						<button onClick={onToggleMenu}
							name={menuOpen ? 'close' : 'menu'}
							className="text-3xl cursor-pointer md:hidden">
							<span class="sr-only">Open main menu</span>
							<svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
							<svg class="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>

						</button>

					</div>



					<div
						className={`nav-links duration-500 md:static absolute bg-green-800 text-white   left-0 ${menuOpen ? 'top-[9%]' : 'top-[-100%] items-center justify-content-center'
							} md:w-auto w-full flex  `} 
					>
						<ul className="flex md:flex-row flex-col md:items-center  md:justify-content-center p-2 border-y-2 border-white ">

						<li className={`md:hidden ${menuOpen ? 'md:hidden' : 'md:hidden'}`}>
								<button class="text-white  font-semibold hover:text-purple-600 " onClick={onCloseMenu}>
								<i class="bi bi-x-square-fill"></i>
								</button>
							</li>
							<li>
								<a href="/specials" class="text-white  btn px-3">Specials</a>
							</li>
							<li className="nav-item">
								<div className="btn-group dropend">
									<button
										type="button"
										className="btn  dropdown-toggle font-semibold text-white"
										data-bs-toggle="dropdown" 
										aria-expanded="false"
									>
										Products
									</button>
									<ul className="dropdown-menu">
										<li>
											<a href="/products" class=" dropdown-item hover:text-green-900 ">All Products</a>
										</li>
										<li>
											<a href="/products/carpet" class=" dropdown-item hover:text-green-900 ">Carpet</a>
										</li>
										<li>
											<a href="/products/hardwood" class=" dropdown-item hover:text-green-900 ">Hardwood</a>
										</li>
										<li>
											<a href="/products/laminate" class=" dropdown-item hover:text-green-900 ">Laminate</a>
										</li>
										<li>
											<a href="/products/Tile" class=" dropdown-item hover:text-green-900 ">Tile</a>
										</li>
										<li>
											<a href="/products/luxuryvinyl" class=" dropdown-item hover:text-green-900 ">luxury Vinyl</a>
										</li>
										<li>
											<a href="/products/vinyl" class=" dropdown-item hover:text-green-900 ">Vinyl</a>
										</li>
										<li>
											<a href="/products/glasstile" class=" dropdown-item hover:text-green-900 ">Glass Tile</a>
										</li>
										<li>
											<a href="/products/furniture" class=" dropdown-item hover:text-green-900 ">Furniture</a>
										</li>
										<li>
											<a href="/products/hardware" class=" dropdown-item hover:text-green-900 ">Hardware</a>
										</li>
										
										
									</ul>
								</div>
							</li>
							<li>
								
							</li>
							<li>

								<a href="/flooring-services" class="text-white  btn px-3">Services</a>
							</li>
							<li>
								<a href="/reviews" class="text-white btn px-3">Reviews</a>
							</li>
							<li>
								<a href="/financing" class="text-white  btn px-3">Financing</a>
							</li>
							<li>
								<a href="/inspiration" class="text-white  btn px-3">Inspiration</a>
							</li>
							<li>
								<a href="/about-us" class="text-white  btn px-3">About</a>
							</li>
							<li>
								<a href="/contact" class="text-white  btn px-3">Contact Us</a>
							</li>
							<li className={`md:hidden ${menuOpen ? 'md:hidden' : 'md:hidden'}`}>
										<a href="/free-estimate" class=" text-white  btn px-3">Request an Estimate</a>

							</li>


						</ul>
					</div>
				</div>




			</div>


		</div>
	)
}

export default Header
